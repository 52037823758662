import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";

import { Stat, Chat, Transaction } from "./dashboard.model";
import { Router } from "@angular/router";
// import {
//   statData,
//   revenueChart,
//   salesAnalytics,
//   sparklineEarning,
//   sparklineMonthly,
//   chatData,
//   transactions,
// } from "./data";
import { DataService } from "src/app/shared/Services/data.service";
import { HttpClient } from "@angular/common/http";
// import { ChartComponent } from "ng-apexcharts";
import { Subject } from "rxjs";
import { ShareService } from "src/app/shared/Services/shared.service";
export interface ChartType {
  series?: any;
  plotOptions?: any;
  stroke?: any;
  dataLabels?: any;
  chart?: any;
  legend?: any;
  colors?: any;
  labels?: any;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit, OnDestroy {
  // @ViewChild("chart") chart: ChartComponent;
  private destroy$: Subject<void> = new Subject<void>();

  // public sparklineEarning: Partial<any>;
  sparklineEarning: any = {};
  term: any;
  chatData: Chat[];
  transactions: Transaction[];
  custId;
  apiInterval: any;
  unit_cost;
  enable: boolean = false;
  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private http: HttpClient,
    private router: Router,
    private sharedService: ShareService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custId = user["user"]["custId"];
    this.unit_cost = user["user"]["powerUnitCost"];
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  // revenueChart: ChartType;
  // salesAnalytics: ChartType;
  // sparklineEarning: ChartType;
  // sparklineMonthly: ChartType;

  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;

  options = {
    layers: [
      tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909),
  };
  showGraph: boolean = false;
  toggleMe($event, data) {
    console.log($event, data);
    if ($event === true) {
      this.showGraph = true;
      console.log("hello");
    } else if ($event === false) {
      console.log("hello man");
      this.showGraph = false;
    }
  }
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "K2" },
      { label: "Dashboard", active: true },
    ];
    this.formData = this.formBuilder.group({
      message: ["", [Validators.required]],
    });
    console.log(this.custId);

    this.getCustData(this.custId);
    this.lossCount(this.custId);
    this.apiInterval = setInterval(() => {
      this.getCustData(this.custId);
      this.lossCount(this.custId);
    }, 30000);
  }
  ngOnDestroy() {
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }
  current: any;
  avail: any;
  m_effic: any;
  perf: any;
  quality: any;
  parts: any;
  prodLoss: any;
  energycost: any;
  machine_List: any = [];
  lossCount(custId) {
    var device = {
      custID: custId,
    };
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/all_prod_loss?code=EQKwxxetpIxVO4xNM167f-ckvJy47SkjHs_hhDJ3_w83AzFuKdMVCQ==",
        device
      )
      .subscribe((res) => {
        console.log("loss", res);
        this.prodLoss = res["total_prod_loss"];
        this.energycost = res["total_power_unit_cost"];
      });
  }
  getCustData(custId) {
    var device = {
      custID: custId,
    };
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/devices_oee?code=IDafVwREaejNu5cZkPFu9KcbltWw2a4YKmBewQD5HrteAzFuh1QEQg==",
        device
      )
      .subscribe((res) => {
        console.log(res, "abcd");
        this.current = res["current"];
        this.avail = res["availability"];
        this.m_effic = res["oee"];
        this.perf = res["performance"];
        this.quality = res["quality"];
        this.parts = res["part_count"];
        this.machine_List = res["device_List"];
        this.sharedService.setShiftData({
          shift: res["Shift_List"],
        });
        var shift: any = {
          shift: res["Shift_List"],
        };
        sessionStorage.setItem("shiftInfio", JSON.stringify(shift));

        this.oeechart(this.machine_List);
      });
  }
  unitcost(data) {
    console.log(data, this.unit_cost);
    return data * parseInt(this.unit_cost);
  }
  epochToHHMMSS(epochTime) {
    if (typeof epochTime === 'undefined') {
      return '-';
  }
    console.log(epochTime)

    const isMilliseconds = epochTime > 9999999999;
    const date = new Date(isMilliseconds ? epochTime : epochTime * 1000);

    // Extract day, month, year, hours, minutes, and seconds in local time
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');

    // Format the date and time as dd/mm/yy hh:mm:ss AM/PM in local time
    return `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  }
  statusColor(data) {
    console.log(data, "ststsus");

    switch (data) {
      case 1: {
        return "#ffff94";
        break;
      }
      case 0: {
        return "#f7c030";
        break;
      }
      case 2: {
        return "#548237";
        break;
      }
      case 3: {
        return "#bfe0a5";
        break;
      }
      case 4: {
        return "#ee4932";
        break;
      }
      default: {
        return "#0092ff";
        //statements;
        break;
      }
    }
  }
  oeechart(data) {
    console.log(data, "data");

    for (let i = 0; i < data.length; i++) {
      this.sparklineEarning[i] = {
        series: [data[i].oee ? data[i].oee : 0],
        chart: {
          type: "radialBar",
          wight: 60,
          height: 60,
          sparkline: {
            enabled: true,
          },
        },
        tooltip: {
          enabled: true,
        },

        colors: ["#007D79"],
        stroke: {
          lineCap: "round",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
            },
            track: {
              margin: 0,
            },

            dataLabels: {
              show: false,
            },
          },
        },
      };
    }
    // console.log(this.sparklineEarning[i]);
  }

  redirctTO(data, name, type) {
    console.log(data);

    this.sharedService.setCheckedList({
      custID: this.custId,
      deviceID: data,
      deviceName: name,
      deviceType: type,
    });
    var record: any = {
      custID: this.custId,
      deviceID: data,
      deviceName: name,
      deviceType: type,
    };
    // sessionStorage.setItem("shiftInfio", JSON.stringify(shift));
    sessionStorage.setItem("machineInfio", JSON.stringify(record));
    this.router.navigate(["/machineInfo"]);
  }
  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get("message").value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: "right",
        name: "Ricky Clark",
        message,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });

      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null,
      });
    }

    this.chatSubmit = true;
  }
}
