import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import { isValidNumber } from "libphonenumber-js";

export function maxDigitsValidator(maxDigits: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    const valueStr: string = control.value.toString();
    const valueDigits: number = valueStr.replace(".", "").length; // Exclude decimal point

    return valueDigits > maxDigits
      ? { maxDigits: { requiredDigits: maxDigits } }
      : null;
  };
}

export function maxDigitsValidatorforConfig(
  maxDigitsBeforeDot: number,
  maxDigitsAfterDot: number
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    const valueStr: string = control.value.toString();
    const dotIndex: number = valueStr.indexOf(".");

    let digitsBeforeDot: number;
    let digitsAfterDot: number;

    if (dotIndex !== -1) {
      digitsBeforeDot = dotIndex;
      digitsAfterDot = valueStr.length - dotIndex - 1; // Exclude dot
    } else {
      digitsBeforeDot = valueStr.length;
      digitsAfterDot = 0;
    }

    return digitsBeforeDot > maxDigitsBeforeDot ||
      digitsAfterDot > maxDigitsAfterDot
      ? { maxDigits: { maxDigitsBeforeDot, maxDigitsAfterDot } }
      : null;
  };
}

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = control.value;
    const isValid = /^\d{10}$/.test(phoneNumber);
    return isValid ? null : { invalidPhoneNumber: true };
  };
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function ngxphoneNumberValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const phoneNumber = control.value?.number;
  if (!phoneNumber || phoneNumber.trim() === "") {
    return null;
  }

  if (!isValidNumber(phoneNumber)) {
    return { invalidPhoneNumber: true };
  }

  return null;
}

export function binFileValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const files = control.value;
  console.log(files,"files")
  if (!files || files.length === 0) {
    return null;
  }
  const invalidFiles = files.filter((fileControl: any) => {
    const fileName = fileControl?.file.name || ""; 
    console.log(fileName,fileControl,"fileName")
    return !fileName.toLowerCase().endsWith(".bin");
  });
  return invalidFiles.length > 0 ? { invalidFileType: true } : null;
}
