import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
// import { options } from '@amcharts/amcharts4/core';

@Injectable({
  providedIn: "root",
})
export class DataService {
  url = environment.url;

  // header = new HttpHeaders().set(
  //   "token",
  //   sessionStorage.getItem("token")
  // );
  constructor(private http: HttpClient) {}

  get(params) {
    return this.http.get(this.url + params);
  }

  post(params, data) {
    return this.http.post(this.url + params, data);
  }

  put(params, data) {
    return this.http.put(this.url + params, data);
  }

  delete(params) {
    return this.http.delete(this.url + params);
  }

  upload(data) {
    return this.http.put(this.url + "upload", data);
  }
}
