import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";

import { DataService } from "src/app/shared/Services/data.service";
import { HttpClient } from "@angular/common/http";
import * as echarts from "echarts";
import { ChartType } from "./echart/echart.model";
import { ShareService } from "src/app/shared/Services/shared.service";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "app-moreinfo",
  templateUrl: "./moreinfo.component.html",
  styleUrls: ["./moreinfo.component.scss"],
})

/**
 * Dashboard Component
 */
export class MoreInfoComponent implements OnInit, AfterViewInit {
  donughnutChart: ChartType;
  private destroy$: Subject<void> = new Subject<void>();
  custName: any;
  shift_Time;
  userName: any = "";
  role: any;
  device_bar_chart:any;
  constructor(
    public formBuilder: FormBuilder,
    private dataservice: DataService,
    private http: HttpClient,
    private router: Router,
    private sharedService: ShareService
  ) {
    var user = JSON.parse(sessionStorage.getItem("currentUser"));
    this.custName = user["user"];
    this.role = this.custName.role;
    this.userName = sessionStorage.getItem("Selected_CustomerName");
    this.shift_Time = this.custName["currentShift"];
    console.log(user);
  }
  showGantt: boolean = false;
  selectedCars = "All Shift";
  // cars = [
  //   { id: 1, name: "Volvo" },
  //   { id: 2, name: "Saab", disabled: true },
  //   { id: 3, name: "Opel" },
  //   { id: 4, name: "Audi" },
  // ];

  statData = [
    {
      icon: "ri-stack-line",
      title: "Number of Sales",
      value: "1452",
    },
    {
      icon: "ri-store-2-line",
      title: "Sales Revenue",
      value: "$ 38452",
    },
    {
      icon: "ri-briefcase-4-line",
      title: "Average Price",
      value: "$ 15.4",
    },
    {
      icon: "ri-briefcase-4-line",
      title: "Average Price",
      value: "$ 15.4",
    },
  ];
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;

  options = {
    layers: [
      tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution: "...",
      }),
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909),
  };
  deviceName;
  apiInterval: any;
  currentShift;
  allShift = [];
  params: any;
  intervalFlag: boolean = true;
  deviceType;
  prodLoss: any;
  energycost: any;
  ngOnInit(): void {
    // var machinedtails = this.sharedService.checkedList.getValue();
    var shared_data = JSON.parse(sessionStorage.getItem("machineInfio"));
    this.params = shared_data;
    console.log(this.params, "abcd");
    // var shift = this.sharedService.shiftList.getValue();
    var shift = JSON.parse(sessionStorage.getItem("shiftInfio"));
    this.allShift = shift["shift"];
    // this.params.shiftName = "ALL";
    // (this.params.startTime = this.allShift[0].startTime),
    //   (this.params.endTime = this.allShift[this.allShift.length - 1].endTime);
    console.log(shift, this.params, this.allShift, this.selectedCars);
    // this.currentShift = this.getCurrentShiftName(shift["shift"]);
    this.currentShift = this.getCurrentShiftName(shift["shift"]);
    this.selectedCars=this.currentShift['shiftName']
    console.log(this.currentShift, this.allShift, "cuushift");
    this.params.shiftName = "all";
    var shiftName = this.getCurrentShift(shift["shift"]);
    console.log(shiftName);
    this.deviceName = this.params.deviceName;
    this.deviceType = this.params.deviceType;
    delete this.params.deviceName;

    console.log(this.params, "moreInfo");
    this.breadCrumbItems = [
      { label: this.userName ? this.userName : "K2" },
      { label: "Machine_Info", active: true },
    ];
    this.scatterChart(this.params);
    // this.gattchart(this.params);
    // this.getGanttChart();
    this.getCustData(this.params);
    this.lossCount(this.params);
    this.apiInterval = setInterval(() => {
      if (this.intervalFlag === true) {
        this.getCustData(this.params);
        this.lossCount(this.params);
      }
    }, 30000);

    // this.selectValue = ["Alaska", "Hawaii", "California", "Nevada"];
  }

  lossCount(params) {
    delete this.params.shiftName;
    console.log(params, "params");
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/prod_loss?code=EB7UcHbHBZ2PwKbhP-Clxp14bPqRpJpvxI4DW8CPmYCfAzFuSH3iMA==",
        params
      )
      .subscribe((res) => {
        console.log("params", res);
        this.prodLoss = res["total_prod_loss"];
        this.energycost = res["power_unit_cost"];
      });
  }
  selectedShift($event) {
    console.log($event, this.currentShift);
    this.selectedCars = $event;
    if (
      this.currentShift.usershiftId === this.selectedCars ||
      this.selectedCars === "All Shift"
    ) {
      this.intervalFlag = true;
    } else {
      this.intervalFlag = false;
    }
    if (this.selectedCars === "All Shift") {
      this.params.shiftName = "all";
      // (this.params.startTime = this.allShift[0].startTime),
      //   (this.params.endTime = this.allShift[this.allShift.length - 1].endTime);
    } else {
      this.params.shiftName = this.selectedCars;
      // let selectedshift = this.allShift.filter(
      //   (x) => x.shiftName === this.selectedCars
      // );
      // (this.params.startTime = selectedshift[0].startTime),
      //   (this.params.endTime = selectedshift[0].endTime);
    }
    this.showChart = false;
    this.showPieChart = false;
    // if(this.intervalFlag === false){
    this.getCustData(this.params);
    this.scatterChart(this.params);
    // this.gattchart(this.params);
    // }
  }
  ngAfterViewInit(): void {}
  ngOnDestroy() {
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  // getCurrentShiftName(shifts) {
  //   // Get current time
  //   const currentTime = new Date();
  //   const currentHour = currentTime.getHours();
  //   const currentMinute = currentTime.getMinutes();
  //   const currentSecond = currentTime.getSeconds();

  //   // Convert current time to seconds for easier comparison
  //   const currentTimeInSeconds =
  //     currentHour * 3600 + currentMinute * 60 + currentSecond;

  //   // // Define shifts
  //   // const shifts = [
  //   //   {
  //   //     shiftName: "SH1",
  //   //     startTime: "00:00:00",
  //   //     endTime: "07:59:59",
  //   //   },
  //   //   {
  //   //     shiftName: "SH2",
  //   //     startTime: "08:00:00",
  //   //     endTime: "15:59:59",
  //   //   },
  //   //   {
  //   //     shiftName: "SH3",
  //   //     startTime: "16:00:00",
  //   //     endTime: "23:59:59",
  //   //   },
  //   // ];

  //   // Find the shift that matches the current time
  //   for (let i = 0; i < shifts.length; i++) {
  //     const shift = shifts[i];
  //     const startTimeInSeconds = this.getSecondsFromString(shift.startTime);
  //     const endTimeInSeconds = this.getSecondsFromString(shift.endTime);
  //     console.log(startTimeInSeconds, "startTime", endTimeInSeconds);
  //     console.log(currentTimeInSeconds, "currentTime");

  //     if (
  //       currentTimeInSeconds >= startTimeInSeconds &&
  //       currentTimeInSeconds <= endTimeInSeconds
  //     ) {
  //       return shift;
  //     }
  //   }

  //   return "No shift found";
  // }
  getCurrentShift(shifts) {
    const currentTime = new Date();
    const currentTimeString = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;
    const statuses = [];
    for (const shift of shifts) {
      if (
        this.compareTimes(currentTimeString, shift.startTime) >= 0 &&
        this.compareTimes(currentTimeString, shift.endTime) <= 0
      ) {
        statuses.push({
          shiftName: shift.shiftName,
          status: "Running",
        });
      } else if (this.compareTimes(currentTimeString, shift.startTime) < 0) {
        statuses.push({
          shiftName: shift.shiftName,
          status: "Upcoming",
        });
      } else {
        statuses.push({
          shiftName: shift.shiftName,
          status: "Past",
        });
      }
    }
    // If no shift is running or upcoming, it must be past the last shift
    return statuses;
  }
  compareTimes(time1, time2) {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);

    if (hours1 !== hours2) {
      return hours1 - hours2;
    } else if (minutes1 !== minutes2) {
      return minutes1 - minutes2;
    } else {
      return seconds1 - seconds2;
    }
  }
  getCurrentShiftName(shifts) {
    const currentTime = new Date(); // Current time

    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentSecond = currentTime.getSeconds();

    const currentSeconds =
      currentHour * 3600 + currentMinute * 60 + currentSecond;
    let startTime = moment(shifts[0].startTime, "HH:mm:ss").format("HH:mm:ss");
    let endTime = moment(shifts[0].endTime, "HH:mm:ss")
      .add(1, "seconds")
      .format("HH:mm:ss");
    if (shifts.length == 1 && startTime == endTime) {
      return shifts[0];
    } else {
      for (const shift of shifts) {
        let startSeconds = this.getSecondsFromString(shift.startTime);
        let endSeconds = this.getSecondsFromString(shift.endTime);

        // If the shift ends on the next day, add 24 hours to end time
        if (shift.endNextDayFlag) {
          endSeconds += 24 * 3600;
        }
        console.log(
          currentSeconds,
          "current",
          startSeconds,
          "start",
          endSeconds,
          "end"
        );

        if (currentSeconds >= startSeconds && currentSeconds <= endSeconds) {
          return shift;
        }
      }
    }

    return null; // No matching shift found
  }

  getSecondsFromString(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  epochToHHMMSS(epochTime) {
    if (typeof epochTime === 'undefined') {
      return '-';
  }
    console.log(epochTime)

    const isMilliseconds = epochTime > 9999999999;
    const date = new Date(isMilliseconds ? epochTime : epochTime * 1000);

    // Extract day, month, year, hours, minutes, and seconds in local time
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');

    // Format the date and time as dd/mm/yy hh:mm:ss AM/PM in local time
    return `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
    // return formattedTime;
  }
  current;
  LUT;
  status;
  m_effic;
  parts;
  combinedData;
  availability;
  quality;
  perf;
  pieStatus;
  r_power;
  a_power;
  energy;
  t_energy;
  showPieChart: boolean = false;
  finalcombinedData:any = []
  getCustData(param) {
    // var device = {
    //   custID: custId,
    // };
    if(param.shiftName) {}else{
    this.params.shiftName = "all";
    }
    console.log(param);

    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/device_oee?code=vbtZrAQPwenO4iwgJR58jO8pLSM0VUo8MtYetHaCM1KwAzFu3ekVbg==",
        param
      )
      .subscribe((res: any) => {
        console.log(res, "resss", res["oee"]);
        this.current = res["current"];
        this.r_power = res["real_power"];
        this.a_power = res["apparent_power"];
        this.energy = res["energy_consumption"];
        this.t_energy = res["total_energy"];
        this.LUT = res["LUT"];
        this.m_effic = res["oee"] ? res["oee"].toFixed(1) : 0;
        this.perf = res["performance"] ? res["performance"].toFixed(1) : 0;
        this.quality = res["quality"] ? res["quality"].toFixed(1) : 0;
        this.availability = res["availability"]
          ? res["availability"].toFixed(1)
          : 0;
        this.status = res["status"];
        this.parts = res?.part_count || "-";
        // this.clustr_Chart = false;
        // this.showPieChart = false;
        // if (res.hasOwnProperty("status_data")) {
        //   var pieStatus = res["status_data"][0];
        //   const outputData = pieStatus.map((item) => {
        //     return {
        //       value: item.duration,
        //       name: item.status === 0 ? "Idle" : "Running",
        //     };
        //   });
        //   this.showPieChart = true;
        //   this.donughnutChart = this.pieChart(outputData);
        //   console.log(res, pieStatus, outputData, "abcd");
        // }
      });
    this.gattchart(param);
  }
  showChart: boolean = false;
  pie_response: any;
  gattchart(param) {
    console.log(param);
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/device_gantt_chart?code=rTjgE3saT7l_LNXSmiBDlvt3C1_OFBm3as-HY9SeV1YDAzFu-8-HSg==",
        param
      )
      .subscribe((res) => {
        if (res["time"][0].length > 0) {
          // this.showPieChart = true;
          this.showChart = true;
          console.log(res, "ganttchart");
          this.combinedData = res;
          // var outputData = res["chart_data"][0];
          this.devicebarchart(param);
          
          // if (res.hasOwnProperty("chart_data")) {
          //   var pieStatus = res["chart_data"][0];
          //   const outputData = pieStatus.map((item) => {
          //     var color;
          //     if (item.status === "dryrun") {
          //       color = "#ffff94";
          //     } else if (item.status === "running") {
          //       color = "#548237";
          //     } else if (item.status === "idle") {
          //       color = "#f7c030";
          //     } else if (item.status === "warning") {
          //       color = "#bfe0a5";
          //     } else if (item.status === "alarm") {
          //       color = "#ee4932";
          //     }
          //     return {
          //       value: item.duration,
          //       name: item.status,
          //       percent: item.percent,
          //       itemStyle: {
          //         color: color,
          //       },
          //     };
          //   });
          //   this.showPieChart = true;
          //   this.pie_response = outputData;
          //   console.log(res, outputData, this.pie_response, "ganttchart");
          //   this.donughnutChart = this.pieChart(outputData);
          // }
          
          
        }
      });
  }
  clustr_Chart: boolean = false;
  cluster_data;
  devicebarchart(param) {
    if(param.shiftName) {}else{
      this.params.shiftName = "all";
      }
      
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/device_bar_chart?code=mafaTgE3c-yo-6dAVaaD0BYbWI4WLxbG7p846_5Q_2vNAzFuhhVRZg%3D%3D",
        param
      )
      .subscribe((res) => {
        this.finalcombinedData = []
        console.log(res,"devicebarchat")
        this.device_bar_chart = res
        if (res.hasOwnProperty("chart_data")) {
          var pieStatus = res["chart_data"][0];
          const outputData = pieStatus.map((item) => {
            var color;
            if (item.status === "dryrun") {
              color = "#ffff94";
            } else if (item.status === "running") {
              color = "#548237";
            } else if (item.status === "idle") {
              color = "#f7c030";
            } else if (item.status === "warning") {
              color = "#bfe0a5";
            } else if (item.status === "alarm") {
              color = "#ee4932";
            }
            return {
              value: item.duration,
              name: item.status,
              percent: item.percent,
              itemStyle: {
                color: color,
              },
            };
          });
          this.showPieChart = true;
          this.pie_response = outputData;
          console.log(res, outputData, this.pie_response, "ganttchart");
          this.donughnutChart = this.pieChart(outputData);
        }
        
        this.finalcombinedData.push(this.combinedData,this.device_bar_chart)
        console.log(this.finalcombinedData,"finalcombinedData")
      })
  }
  scatterChart(param) {
    if(param.shiftName){
     console.log("scateerpa")
    }else{
    param.shiftName = "all";
    }
    console.log(param,"scateerpa");
    this.clustr_Chart = false;
    delete this.params.deviceType;
    this.cluster_data = [];
    this.http
      .post(
        "https://k2-oee-prod.azurewebsites.net/api/clustering?code=5tUM7VsH-ItvOhMXCvpooz3fI6q89JM5MD330WduP4FBAzFuZxzZAA==",
        param
      )
      .subscribe((res) => {
        console.log(res["clustering_data"], "scatter");
        if (Object.keys(res).length != 0 && res["clustering_data"].length) {
          this.clustr_Chart = true;
          this.cluster_data = res["clustering_data"];
        }
      });
  }
  secondsToHms(d) {
    // d = Number(d);
    // const h = Math.floor(d / 3600);
    // const m = Math.floor((d % 3600) / 60);
    // const s = Math.floor((d % 3600) % 60);

    // const hDisplay = h > 0 ? (h < 10 ? "0" + h : h) + ":" : "00:";
    // const mDisplay = m > 0 ? (m < 10 ? "0" + m : m) + ":" : "00:";
    // const sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
    // return hDisplay + mDisplay + sDisplay;
    d = Number(d);
    const hours = Math.floor(d / 60);
    const minutes = Math.floor(d % 60);
    const seconds = Math.round((d * 60) % 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  houers(data) {
    console.log(data, this.pie_response);
    let valueInHMS = "00:00:00";
    if (this.pie_response) {
      let existing = this.pie_response.find((item) => item.name == data);
      if (existing == undefined) {
        valueInHMS = "00:00:00";
      } else {
        valueInHMS = this.secondsToHms(existing.value);
      }
    }
    return valueInHMS;
  }
  statusColor(data) {
    console.log(data, "ststsus");
    switch (data) {
      case 1: {
        return "#ffff94";
        break;
      }
      case 0: {
        return "#f7c030";
        break;
      }
      case 2: {
        return "#548237";
        break;
      }
      case 3: {
        return "#bfe0a5";
        break;
      }
      case 4: {
        return "#ee4932";
        break;
      }
      default: {
        return "#0092ff";
        //statements;
        break;
      }
    }
  }
  colorCode(data) {
    if (data === "dryrun") {
      return "#ffff94";
    } else if (data === "running") {
      return "#548237";
    } else if (data === "idle") {
      return "#f7c030";
    } else if (data === "warning") {
      return "#bfe0a5";
    } else if (data === "alarm") {
      return "#ee4932";
    }
  }
  pieChart(data) {
    return {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          var value = params.value;
          var hours = Math.floor(value / 60);
          var minutes = Math.floor(value % 60);
          var seconds = Math.floor((value * 60) % 60);
          var formattedValue =
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0");

          return `${params.seriesName}<br/>${params.name}: ${formattedValue} (${params.percent}%)`;
        },
      },
      legend: {
        top: "5%",
        left: "center",
        // orient: "vertical",
        // x: "left",
        // data: ["Running", "Idle"],
        // textStyle: { color: "#8791af" },
      },
      // color: ["#f7ff00", "#5fff00", "#f1b44c", "#50a5f1", "#34c38f"],
      series: [
        {
          name: "Status",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            formatter: function (params) {
              // If axis.type is 'time'
              return "some text" + echarts.format.formatTime(params.value);
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: data,
        },
      ],
    };
  }
  ganttData: any;
  getGanttChart() {
    var current_data = {
      start_time: "1707552000000",
      end_time: "1707580799000",
      device_id: "348518678830",
    };

    this.http
      .post(
        "https://k2functionapp.azurewebsites.net/api/gantt_chart?code=CSIkta8csfvwqNF5QAv9crmrwWMKceBbuRm7j2TVkBagAzFuA4hlhw==",
        current_data
      )
      .subscribe((res) => {
        console.log(res, "resss..............");

        if (res) {
          console.log(res, "gantta");

          this.ganttData = res;
          this.showGantt = true;
        }
      });
  }

  addSample(act) {
    console.log("Hai", this.params, this.deviceName);
    this.router.navigate([
      "/recordsample",
      { mch: this.deviceName, view: act },
    ]);
  }
}
