<div class="container-fluid">
  <app-pagetitle
    title="{{ deviceName }}:{{ deviceType }}"
    [breadcrumbItems]="breadCrumbItems"
  ></app-pagetitle>
  <div class="row">
    <div [ngClass]="role === 'SUPERADMIN' ? 'col-md-12' : 'col-md-10'">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <!-- <p class="text-truncate font-size-14 mb-2">Shift Details</p> -->
              <div class="row">
                <div
                  class="col-md-3 text-center"
                  *ngFor="let shift of allShift"
                >
                  <div
                    [ngClass]="{
                      'current-shift':
                        currentShift &&
                        currentShift.shiftName === shift.shiftName
                    }"
                  >
                    <p>{{ shift.shiftName }}</p>
                    <h4>{{ shift.startTime }} - {{ shift.endTime }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" *ngIf="role !== 'SUPERADMIN'">
      <div class="card">
        <div class="card-body h-75">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-18 mb-2">Sample Records</p>
              <div class="row justify-content-around">
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    (click)="addSample('records')"
                  >
                    Add
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    (click)="addSample('view')"
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6">
      <div class="row">
        <!-- <div *ngFor="let stat of statData" class="col-md-3"> -->
        <div class="col-md-6">
          <div class="card mb-2">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">
                    Last Update Time
                  </p>
                  <h5 class="mb-0">{{ epochToHHMMSS(LUT) }}</h5>
                </div>
                <div
                  class="spinner-grow m-1"
                  role="status"
                  [style.color]="statusColor(status)"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <!-- <div class="text-primary">
                  <i class="ri-award-fill font-size-24"></i>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-2">
            <div class="card-body">
              <p class="text-truncate font-size-14 mb-2">
                <!-- Cuurent Shift :{{ currentShift["shiftName"] }} -->
                Select Shift:
              </p>
              <ng-select
                [(ngModel)]="selectedCars"
                (change)="selectedShift($event)"
              >
                <ng-option [value]="'All Shift'" selected>All Shift</ng-option>
                <ng-option
                  *ngFor="let data of allShift"
                  [value]="data.usershiftId"
                  >{{ data.shiftName }}</ng-option
                >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <span class="text-primary"> M-Efficiency</span>
                  <h4 class="mb-0">
                    {{ m_effic ? m_effic : 0 }}
                    <span style="font-size: 1rem !important">% </span>
                  </h4>
                </div>
                <div class="col-md-3">
                  <span class="text-primary"> M-utilisation</span>
                  <h4 class="mb-0">
                    {{ perf ? perf : 0 }}
                    <span style="font-size: 1rem !important">% </span>
                  </h4>
                </div>
                <div class="col-md-3">
                  <span class="text-primary"> Prod Loss</span>
                  <h4 class="mb-0">
                    {{ prodLoss ? prodLoss : 0 }}
                    <span style="font-size: 1rem !important">Rs </span>
                  </h4>
                </div>
                <div class="col-md-3">
                  <span class="text-primary"> Energy Cost</span>
                  <h4 class="mb-0">{{ energycost ? energycost : 0 }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-2">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Total Parts</p>
                  <h4 class="mb-0">{{ parts ? parts : 0 }}</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-inbox-unarchive-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">
                    Machine Efficency
                  </p>
                  <h4 class="mb-0">{{ m_effic ? m_effic : 0 }} %</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">
                    Machine Performance
                  </p>
                  <h4 class="mb-0">{{ perf ? perf : 0 }} %</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">
                    Machine Availability
                  </p>
                  <h4 class="mb-0">{{ availability ? availability : 0 }} %</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Machine Quality</p>
                  <h4 class="mb-0">{{ quality ? quality : 0 }} %</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-settings-line font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-6">
          <div class="card mb-2">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Current</p>
                  <h4 class="mb-0">
                    {{ current ? current : 0 }}
                    <span style="font-size: 1rem !important">amps </span>
                  </h4>
                </div>
                <div class="text-primary">
                  <i class="ri-wireless-charging-fill font-size-24"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <span class="text-primary"> R-Power</span>
                  <h4 class="mb-0">
                    {{ r_power ? r_power : 0 }} <span class="txt-font">W</span>
                  </h4>
                </div>
                <div class="col-md-4">
                  <span class="text-primary"> A-Power</span>
                  <h4 class="mb-0">
                    {{ a_power ? a_power : 0 }} <span class="txt-font">VA</span>
                  </h4>
                </div>
                <!-- <div class="col-md-3">
                  <span class="text-primary"> Energy</span>
                  <h4 class="mb-0">
                    {{ energy ? energy : 0 }}
                    <span class="txt-font">KwH</span>
                  </h4>
                </div> -->
                <div class="col-md-4">
                  <span class="text-primary"> T-Energy</span>
                  <h4 class="mb-0">
                    {{ t_energy ? t_energy : 0 }}
                    <span class="txt-font"> KwH</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- end row -->
    </div>
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body">
          <!-- <div class="row table-responsive text-center">
            <table style="width: 100%">
              <tr>
                <th>Shift Name</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
              <tbody>
                <tr *ngFor="let data of allShift">
                  <td>{{ data.shiftName }}</td>
                  <td>{{ data.startTime }}</td>
                  <td>{{ data.endTime }}</td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <div
            *ngIf="showPieChart"
            echarts
            [options]="donughnutChart"
            class="demo-chart"
          ></div>
          <hr *ngIf="showPieChart" />
          <div class="row d-flex justify-content-around" *ngIf="showPieChart">
            <div class="bor col-md-2" [style.border-color]="colorCode('idle')">
              <h6>Idle</h6>
              <p>{{ houers("idle") }}</p>
            </div>
            <div
              class="bor col-md-2"
              [style.border-color]="colorCode('dryrun')"
            >
              <h6>Dryrun</h6>
              <p>{{ houers("dryrun") }}</p>
            </div>
            <div
              class="bor col-md-2"
              [style.border-color]="colorCode('running')"
            >
              <h6>Running</h6>
              <p>{{ houers("running") }}</p>
            </div>
            <div
              class="bor col-md-2"
              [style.border-color]="colorCode('warning')"
            >
              <h6>Warning</h6>
              <p>{{ houers("warning") }}</p>
            </div>
            <div class="bor col-md-2" [style.border-color]="colorCode('alarm')">
              <h6>Alarm</h6>
              <p>{{ houers("alarm") }}</p>
            </div>
          </div>
          <div *ngIf="!showPieChart" class="text-center">
            <img src="assets/images/noData.jpg" class="imgs" />
            Please wait we are fetching data / nodata
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <select class="custom-select custom-select-sm">
              <option selected>Apr</option>
              <option value="1">Mar</option>
              <option value="2">Feb</option>
              <option value="3">Jan</option>
            </select>
          </div>
          <h4 class="card-title mb-4">Sales Analytics</h4>

          <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart"
            [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="revenueChart.labels"
            [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
          </apx-chart>
          <div class="row">
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> Product A
                </p>
                <h5>42 %</h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Product B
                </p>
                <h5>26 %</h5>
              </div>
            </div>
            <div class="col-4">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> Product C
                </p>
                <h5>42 %</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="dropdown float-right" ngbDropdown>
            <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown"
              aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              
              <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
              
              <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
              
              <a href="javascript:void(0);" class="dropdown-item">Profit</a>
             
              <a href="javascript:void(0);" class="dropdown-item">Action</a>
            </div>
          </div>

          <h4 class="card-title mb-4">Earning Reports</h4>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <div class="mb-3">
                    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineEarning.series"
                      [chart]="sparklineEarning.chart" [colors]="sparklineEarning.colors"
                      [labels]="sparklineEarning.labels" [dataLabels]="sparklineEarning.dataLabels"
                      [plotOptions]="sparklineEarning.plotOptions">
                    </apx-chart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Weekly Earnings</p>
                  <h5 class="mb-0">$2,523</h5>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineMonthly.series"
                      [chart]="sparklineMonthly.chart" [colors]="sparklineMonthly.colors"
                      [labels]="sparklineMonthly.labels" [dataLabels]="sparklineMonthly.dataLabels"
                      [plotOptions]="sparklineMonthly.plotOptions">
                    </apx-chart>
                  </div>

                  <p class="text-muted text-truncate mb-2">Monthly Earnings</p>
                  <h5 class="mb-0">$11,235</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Status Vs Current</h4> -->
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">Live Gantt Chart</h4>
              <!-- <button
                type="button"
                class="btn btn-success btn-sm"
                (click)="scatterChart(params)"
              >
                <i class="ri-refresh-line align-middle mr-2"></i>Refresh Data
              </button> -->
            </div>
            <div class="col-md-6 text-md-right">
              <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: #f7c030; color: white"
                >
                  Idle
                </p>
              </div>
              <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #ffff94">Dryrun</p>
              </div>
              <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: #548237; color: white"
                >
                  Running
                </p>
              </div>
              <!-- <div class="d-inline-block mr-2">
                <p class="badge" style="background-color: #bfe0a5">warning</p>
              </div> -->

              <!-- <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: #ee4932; color: white"
                >
                  alarm
                </p>
              </div> -->
              <div class="d-inline-block mr-2">
                <p
                  class="badge"
                  style="background-color: white; color: black"
                >
                  No Data
                </p>
              </div>
            </div>
          </div>
          <app-combinechart
            *ngIf="showChart"
            [gaugeArray]="finalcombinedData"
          ></app-combinechart>
          <div *ngIf="!showChart">
            <img src="assets/images/noData.jpg" width="100%" />
            Please wait we are fetching data / nodata
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>

    <!-- <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Gantt Chart</h4>
          <app-ganttchart *ngIf="showGantt"></app-ganttchart>
        </div>
      </div>
    
    </div> -->
  </div>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-between">
            <!-- <h5>Current Scatter</h5> -->
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="scatterChart(params)"
            >
              <i class="ri-refresh-line align-middle mr-2"></i>Refresh Data
            </button>
          </div>

          <app-scatter-chart
            *ngIf="clustr_Chart"
            [clusterArray]="cluster_data"
          ></app-scatter-chart>
          <div *ngIf="!clustr_Chart">
            <img src="assets/images/noData.jpg" width="100%" />
            Please wait we are fetching data / nodata
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
